import * as React from 'react'
import { IBaseModule } from "../BaseModule";
import { readData, writeData } from '../../../utils/Firebase';
import { GridHeightsSM, GridWidthsSM, IGrid, ILayouts, breakpointTypes, firebaseModulePaths, getModulesPath, modules as m, modules } from '../../../utils/constants';
import { CardModule } from '../CardModule/CardModule';
import { generateId } from '../../../utils/Utils';
import { PlusIcon } from '../../../icons/PlusIcon';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { Row } from '../../../components/layout/row/Row';
import { Column } from '../../../components/layout/column/Column';
import { TData } from '../../../app/interface';
import { IReactGridModuleProps, ReactGridModule } from '../ReactGridModule/ReactGridModule';
import { Layout } from '../../Layout';
import { ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import "./BoardModule.scss";

export interface IBoardModuleProps extends IReactGridModuleProps {
    onCreateCard?: (cardModule: CardModule | ScheduledActivityCardModule, boardModule: BoardModule) => void;
}

export class BoardModule extends ReactGridModule {
    onCreateCard: (cardModule: CardModule | ScheduledActivityCardModule, boardModule: BoardModule) => void;

    constructor(baseProps: IBaseModule, boardModuleProps: IBoardModuleProps) {
        super(baseProps, boardModuleProps);
        this.onCreateCard = boardModuleProps.onCreateCard;
        // Object.assign(this, boardModuleProps);
        this.noBox = false;
        this.dataTestid = `board-module`;
    }

    cardArrayToObject = (cardArray: IGrid[]): { [key: string]: IGrid } => {
        return cardArray.reduce((previous, current, index, array) => {
            previous[current.i] = current;
            return previous;
        }, {})
    }

    getCreationDialog(): JSX.Element {
        return <div>
            {/* <InputConfirm label='Namn på lista' okButtonTestid='boardok' onConfirm={name => {
                onCreate(name);
            }} /> */}
        </div>
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: {
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.FULL,
                h: GridHeightsSM.FULL,
            },
            [breakpointTypes.LG]: {
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.QUARTER,
                h: GridHeightsSM.HALF,
            }
        }
    }

    getBoardItemType(name: string): CardModule {
        const baseProps = { id: generateId(), name, type: modules.CARD, user: this.user, parentLayoutId: "" }
        const cardModule = new CardModule(
            baseProps,
            { 
                onCardCreate: () => console.log()
             }
        )
        return cardModule;
    }

    createCardModule(name: string, scheduledActivity?: ScheduledActivity): CardModule | ScheduledActivityCardModule {
        const cardModule = this.getBoardItemType(name);
        return cardModule
    }

    // createLayout(cardModule) {
    //     const grid: IGrid = { x: 0, y: 0, w: 6, h: 4, i: cardModule.id }
    //     if (!this.layout) {
    //         this.layout = new Layout({ [breakpointTypes.SM]: { [grid.i]: grid }, [breakpointTypes.LG]: { [grid.i]: grid } });
    //         this.elements = this.layoutToRenderedElements([grid], [cardModule]);
    //     }
    //     else {
    //         this.layout[breakpointTypes.LG][grid.i] = grid;
    //         this.elements = [...this.elements, this.moduleToElement(cardModule)];
    //     }
    // }

    createBoardItem(name: string) {
        const cardModule: CardModule = this.createCardModule(name);
        this.addModule(cardModule);
        this.renderModule();
        this.closeModal();
        this.onCreateCard(cardModule, this);
    }

    deleteBoardItem = (id: string) => {
        super.removeModule(id);
    }

    renderTopMenu = (): JSX.Element => {
        return <Column alignLeft className='board-top'>
            <Row>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(
                            <div>
                                <InputConfirm testId='add-board-card' label='Namn på kortet' onConfirm={name => {
                                    this.createBoardItem(name);
                                }} /></div>)
                    }}>
                    Lägg till kort
                    <PlusIcon />
                </div>
            </Row>
        </Column>
    }

    render(): JSX.Element {
        return <div className="board-module">
            {super.render()}
        </div>
    }

}