import "./styles.scss"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import "../../../components/layouts/ReactGridLayout.scss";

import { Responsive, WidthProvider } from 'react-grid-layout'
import { TData } from "../../../app/interface";
import { BaseModule, IBaseModule } from "../BaseModule";
import { breakpoints, breakpointTypes, cols, getModulesPath, IGrid, modules } from "../../../utils/constants";
import { ILayout, Layout } from "../../Layout";
import { generateId } from "../../../utils/Utils";
import { CardModule } from "../CardModule/CardModule";
import { writeData } from "../../../utils/Firebase";
import { on } from "events";
import { ScheduledActivityCardModule } from "../ScheduledActivityCardModule/ScheduledActivityCardModule";

const ResponsiveGridLayout = WidthProvider(Responsive)

export interface IReactGridModuleProps {
    layout?: ILayout;
    testDraggable?: boolean;
    modules?: BaseModule[];
    onDragStart: (newGrid: IGrid) => void;
    onDragStop: (newGrid: IGrid[], modules: BaseModule[]) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
    onResizeStop: (layout: IGrid[]) => void;
    onRemoveModule: (module: BaseModule, gridModule: ReactGridModule) => void;
}

export class ReactGridModule extends BaseModule {
    modules: BaseModule[] = [];
    layout?: Layout;
    testDraggable?: boolean;
    onDragStart: (newGrid: IGrid) => void;
    onDragStop: (newGrid: IGrid[], modules: BaseModule[]) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
    onResizeStop: (layout: IGrid[]) => void;
    onRemoveModule: (moduleId: BaseModule, gridModule: ReactGridModule) => void;

    constructor(baseProps: IBaseModule, reactGridModuleProps: IReactGridModuleProps) {
        super(baseProps);
        Object.assign(this, reactGridModuleProps);
        this.layout = new Layout(reactGridModuleProps.layout);
        this.modules = reactGridModuleProps.modules || [];
        this.testDraggable = reactGridModuleProps.testDraggable;
        this.modules.forEach(m => {
            if (m instanceof CardModule || m instanceof ScheduledActivityCardModule) {
                m.parentModule = this
            }
        });
        this.noBox = true;
    }

    addModule = (module: BaseModule, customGrid?: Partial<IGrid>) => {
        const grid: IGrid = { x: 0, y: 0, w: 4, h: 4, i: module.id, ...customGrid }
        if (!this.layout) {
            this.layout = new Layout({ [breakpointTypes.SM]: { [grid.i]: grid }, [breakpointTypes.LG]: { [grid.i]: grid } });
        }
        else {
            this.layout[breakpointTypes.LG][grid.i] = grid;
        }
        if(module instanceof CardModule) {
            module.parentModule = this;
        }
        this.modules.push(module);
        this.forceRender && this.forceRender();
    }

    public removeModule(moduleId: string) {
        this.layout.removeGrid(moduleId);
        const moduleToRemove = this.modules.find(m => m.id === moduleId);
        this.modules = this.modules.filter(m => m.id !== moduleId);
        this.onRemoveModule && this.onRemoveModule(moduleToRemove, this);
        this.forceRender && this.forceRender();
    }

    addCard = (name: string, customGrid?: Partial<IGrid>) => {
        const cardModule = new CardModule({ id: generateId(), name, type: modules.CARD, user: this.user, parentLayoutId: "" }, {onCardCreate: () => this.addCard(name)})
        this.addModule(cardModule, customGrid);
    }

    getDataContextSubscribeArgs(dataContext: TData): any[] {
        return [dataContext?.modules?.allModules]
    }

    getCreationDialog(): JSX.Element {
        return null;
    }
    customDelete(dataContext: TData): void {

    }
    renderTopMenu(dataContext: TData): JSX.Element {
        return null;
    }

    moduleToElement = (module: BaseModule): JSX.Element => {
        const grid = this.layout.getAllGrids(breakpointTypes.LG).find(g => g.i === module.id)
        const gridItem = <div
            data-testid="grid-item"
            key={module.id}
            data-grid={{ ...grid, isDraggable: this.testDraggable ?? grid.isDraggable ?? true }}
            className={`moduleid-${module.id}`}
        >
            {module.renderModule(this.id)}
        </div>
        return gridItem;
    }

    layoutToRenderedElements = (layout: IGrid[], modules: BaseModule[]): JSX.Element[] => {
        this.layout.updateGridItems(layout, breakpointTypes.LG,);

        if (layout && modules) {
            const grids: JSX.Element[] = [];
            for (const module of modules) {
                const gridItem = this.moduleToElement(module);
                if (Object.keys(gridItem).length) {
                    grids.push(gridItem);
                }
            }
            return grids;
        }
        else {
            return;
        }
    }

    render(): JSX.Element {
        const elements = this.layoutToRenderedElements(this.layout.getAllGrids(breakpointTypes.LG), this.modules);
        if (!elements?.length) {
            return <div>no items :/</div>
        }

        return <div
            className={`react-grid-layout-module pageloader-container dashboard__modules layout-${this.id}`}
            data-testid={this.dataTestid || "layout"}
        >
            <ResponsiveGridLayout
                draggableCancel=".prevent-drag"
                draggableHandle={`.${this.id}`}
                className={`layout`}
                breakpoints={breakpoints}
                cols={cols}
                onDropDragOver={this.onDragOver}
                layout={elements}
                rowHeight={10}
                onResizeStop={this.onResizeStop}
                onDragStart={this.onDragStart}
                onDragStop={this.onDragStop}
                isDroppable={true}
                style={{ width: '100%', height: '700px' }}
                onDrop={() => {
                    // onDrop
                }}
            >
                {elements}
            </ResponsiveGridLayout >
        </div >
    }

    toFirebaseObject() {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            layout: this.layout.getILayout()
        }
    }

    // updateModuleFirebase(): void {
    //     const firebaseData: IBaseModule & {layout: ILayout} = {
    //         id: this.id, user: this.user, type: this.type, name: this.name,
    //         layout: this.layout.getILayout()
    //     }
    //     writeData(getModulesPath(this.user, this.id), firebaseData);
    // }

    updateLayoutFirebase() {
        writeData(`${getModulesPath(this.user, this.id)}/layout`, this.layout.getILayout());
    }

}