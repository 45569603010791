import * as React from 'react'
import InputConfirm from '../input_and_confirm/InputConfirm'
import { Activity } from '../../data/activity/Activity';
import { generateId } from '../../utils/Utils';
import { getNow } from '../../utils/TimeUtils';
import { Tags } from '../tags/Tags';
import { DataContext } from '../../app/DataContext';

export const QuickActivityCreator = ({ onCreate, onTyping = null, hideConfirmButton = false, testId = undefined }) => {
    const dataContext = React.useContext(DataContext);
    // const [tags, setTags] = React.useState([]);
    
    return (<>
        <InputConfirm testId={testId}
            label='Skapa aktivitet'
            onChange={(text) => { if (onTyping) onTyping(text) }}
            hideConfirmButton={hideConfirmButton}
            onConfirm={async val => {
                if (onCreate) {
                    // const t = dataContext.tags.getTagsByName("Aktivitet");
                    // const activityTag = t[0];
                    // if (activityTag) {
                    //     const newActivity = new Activity({ name: val, schedule: [], created: getNow(), id: generateId(), tags: [activityTag.id, ...tags.map(t => t.id)] })
                    //     onCreate(newActivity);
                    // }
                    // else {
                        const newActivity = new Activity({ name: val, schedule: [], created: getNow(), id: generateId(), tags: [] })
                        onCreate(newActivity);
                    // }

                }
            }} />
        {/* <Tags onClose={() => { }} onChange={tags => {
            setTags(tags.map(t => t.id))
        }} /> */}
    </>
    )
}
