import * as React from 'react'
import { Fragment, useEffect, useState } from 'react';
import { DataContext } from '../../../app/DataContext';
import { ActivityRow } from '../../../data/activity/ActivityRow/ActivityRow';
import { sortAttributeFunc } from '../../../utils/Utils';
import { Button } from '../../button/Button';
import { Input } from '../../input/Input';
import { QuickActivityCreator } from '../../quick_activity_creator/QuickActivityCreator';
import { Row } from '../../layout/row/Row';
import './ActivityPicker.scss'
import { Activity } from '../../../data/activity/Activity';
import classNames from 'classnames';

const ActivityPicker = ({activities, onSelect, showCreateQuickActivity = null, onQuickCreate = null, onQuickActivityNameChange = null, hideConfirmButton = false, testId = undefined }) => {
    // const { activities } = React.useContext(DataContext)
    const [filterKeyword, setFilterKeyword] = useState("")
    // const [activities, setActivities] = useState(activities.activities)
    // const [currentActivities, setCurrentActivities] = useState<Activity[]>()
    const [showAsList, setShowAsList] = useState(true);
    const [filter, setFilter] = useState<string>()
    // React.useEffect(() => {
    //     console.log("Heeeeloooooooooooooooooo3************************", activities.activities);
    // }, [])


    // useEffect(() => {
    //     if (activities?.length > 0) {
    //         const rootActivities = activities.filter(a => (!a.parentActivities || a.parentActivities?.length < 1));
    //         setCurrentActivities(rootActivities)
    //     }
    // }, [activities])

    // useEffect(() => {
    //     if (filterKeyword) {
    //         const filteredActivities = activities.activities.filter(a => a.name.toLowerCase().includes(filterKeyword.toLowerCase()))
    //         setActivities(filteredActivities)
    //         setShowAsList(true);
    //     }
    //     else {
    //         setActivities(activities.activities);
    //         setShowAsList(false);
    //     }
    // }, [filterKeyword])

    const getFilteredActivities = () => {
        if (filterKeyword) {
            const filteredActivities = activities.filter(a => a.name.toLowerCase().includes(filterKeyword.toLowerCase()))
            return filteredActivities
            //     setActivities(filteredActivities)
            //     setShowAsList(true);
            // }
            // else {
            //     setActivities(activities.activities);
            //     setShowAsList(false);
            // }
        }
        return activities;
    }

    const renderTree = () => {
        const currentActivities = getFilteredActivities();
        if (!currentActivities?.length) {
            return null;
        }
        return <div className="tree" data-testid={classNames(
            testId && `${testId}-tree`
        )}>{currentActivities.map((activity: Activity, idx) => {
            return <Fragment key={idx}>
                <ActivityRow activity={activity}/*  onBackClick={onBackClick} onForwardClick={onForwardClick} */ onSelect={onSelect} />
            </Fragment>
        })}</div>
    }

    const renderAsList = () => {
        if (!activities?.length) {
            return null;
        }
        return activities.sort((a, b) => sortAttributeFunc(a, b, ["timesCompleted", "name"])).filter(a => {
            if (filter === 'Bara rötter') {
                return a.isRoot();
            }
            else if (filter === 'Ej rötter') {
                return !a.isRoot();
            }
            else if (filter === "Bara löv") {
                return a.isLeaf();
            }
            else {
                return true;
            }
        }).map((activity: Activity, idx) => {
            return <Fragment key={idx}>
                <Row className="margin-bottom">
                    <Button dataTestid="activity-picker-button" label={activity.name} onClick={() => {
                        onSelect(activity)
                    }} />
                </Row>
            </Fragment>
        })

    }

    const renderSearchField = () => {
        return <div className="margin-bottom">
            <Input dataTestid='filter-activities' label={'Filtrera aktiviteter: '} onChange={val => {
                setFilterKeyword(val.toLowerCase())
            }} />
        </div>
    }

    // const onForwardClick = (activity: Activity) => {
    //     const childActivities = activity.childActivities
    //     setCurrentActivities(childActivities)
    // }

    // const onBackClick = (activity: Activity) => {
    //     const parentActivities: Activity[] = activity.parentActivities;
    //     const parent = parentActivities[0];
    //     const sibblings = activities.getSiblingActivities(parent);
    //     setCurrentActivities(sibblings);
    // }
    if(!activities?.length){
        return <div>must send in activities to ActivityPicker</div>
    }

    return (
        <div className="activity-picker" data-testid={testId}>
            {/* {renderSettingsPanel()} */}
            {showCreateQuickActivity && <QuickActivityCreator hideConfirmButton={hideConfirmButton} onTyping={text => {
                if (onQuickActivityNameChange) onQuickActivityNameChange(text);
            }} onCreate={async (newActivity: string) => {
                if (onQuickCreate) {
                    await onQuickCreate(newActivity);
                }
            }} />}
            {renderSearchField()}
            {!showAsList && renderTree()}
            {showAsList && renderAsList()}
        </div>
    )
}

export { ActivityPicker }