import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { IsolatedEnvironment } from './app/IsolatedEnvironment';
import { baseProps, createBoardModule, createCardModule, createTodoBoardModule, getScheduledActivityCardModule, getTodoBoardModuleWithOneItem } from './data/mock/MockData';
import { Row } from './components/layout/row/Row';
import { generateId } from './utils/Utils';
import { CardModule } from './module/modules/CardModule/CardModule';
import { breakpointTypes, modules } from './utils/constants';
import { Layout } from './module/Layout';
import { AppInitiator } from './app/AppInitiator';
import { ScheduledActivityCardModule } from './module/modules/ScheduledActivityCardModule/ScheduledActivityCardModule';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <Signup defaultName="TestUsername" defaultEmail="test@test.test" defaultPassword='test123' onCreateUser={function (startkit: IStartkit): void {
  //   console.log(startkit);
  // } } />
  // <ModuleCreator onCreate={newModule => console.log(newModule)}/>
  // <IsolatedEnvironment testModule={new CardModule(baseProps(modules.CARD, {name: "Card Content"}))} />
  // <AppInitiator />
  // <IsolatedEnvironment testModule={getScheduledActivityCardModule()} />
  // <Row>
    <IsolatedEnvironment testModule={getTodoBoardModuleWithOneItem()} />
  //   <IsolatedEnvironment testModule={createBoardModule(undefined, {
  //     layout: {
  //       [breakpointTypes.LG]: { ["testid"]: {i: "testid", y: 0, w: 4, h: 20, x: 0 } }
  //     },
  //     modules: [new CardModule({ id: "testid", name, type: modules.CARD, user: "testuser", parentLayoutId: "" })]
  //   })
  //   } />
  // </Row>
  // <AppInitiator
  //   width="100%"
  //   testButton
  //   runInIsolation
  //   testModule={reactGridLayoutMockNoModules()}
  // />
  //rootModule.elements = rootModule.layoutToRenderedElements(rootModule.layout?.getAllGrids(breakpointTypes.LG), modules);
  // <AppInitiator 
  //   runInIsolation
  //   testModule={getCalendarEventsModule()}
  // />
  // <AppInitiator
  //   appContextDefaults={
  //     { user: "testuserid" }
  //   }
  // />
  // <AppInitiator {...appMockProps}/>
  // <AppInitiator
  //   {...activityTableMockProps}
  //   runInIsolation={true}
  //   testModule={createTodoBoardModule()}
  // />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

