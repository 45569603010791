import * as React from 'react'
import { BaseModule } from '../BaseModule';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { breakpointTypes, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts, modules } from '../../../utils/constants';
import "./Card.scss";
import { ReactGridModule } from '../ReactGridModule/ReactGridModule';
import classNames from 'classnames';
import { BoxedModule } from '../../BoxedModule';
import { generateId } from '../../../utils/Utils';

export interface ICardModuleProps {
    onCardCreate: (cardModule: CardModule) => void;
    customCardClick?: () => void;
    customCardFront?: (cardModule: CardModule) => JSX.Element;
    onCheck?: Function;
    createdScheduleTime?: number;
    status?: string[];
    selectableCards?: boolean;
    selectedChildrenIds?: string[];
    selectable?: boolean;
    parentModule?: ReactGridModule;
}

export class CardModule extends BaseModule implements ICardModuleProps {
    onCardCreate: (cardModule: CardModule) => void;
    customCardClick?: () => void;
    customCardFront?: (cardModule: CardModule) => JSX.Element;
    onCheck?: Function;
    createdScheduleTime?: number;
    status?: string[];
    selectableCards?: boolean;
    selectedChildrenIds?: string[];
    selectable?: boolean;
    parentModule?: ReactGridModule;

    constructor(baseProps: any, boardModuleProps?: ICardModuleProps) {
        super(baseProps);
        this.noBox = true;
        Object.assign(this, boardModuleProps)
    }

    getCreationDialog(onCreate: (baseModule: BaseModule) => void): JSX.Element {
        return <div>
            <InputConfirm label='Namn på kort' onConfirm={(name) => {
                this.name = name;
                this.type = modules.CARD;
                const cardModuleProps: ICardModuleProps = { onCardCreate: onCreate}
                Object.assign(this, cardModuleProps)
                onCreate(this);
            }} />
        </div>
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: {
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.CARD,
                h: GridHeightsSM.CARD,
            },
            [breakpointTypes.LG]: {
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.CARD,
                h: GridHeightsLG.CARD,
            }
        }
    }

    renderTopMenu(): JSX.Element {
        return null
    }

    customDelete = (): void => {

        this.closeModal();
    }

    createCardDialog = (): JSX.Element => {
        // return InputConfirm label: 'Namn på kort', onConfirm: name => {
        //     return new CardModule({ ...{ id: generateId(), name, type: modules.CARD, parentLayoutId: this.id } });
        // } })
        return <InputConfirm
            label='Namn på kort'
            onConfirm={name => {
                const newCardModule = new CardModule(
                    { ...{ id: generateId(), name, type: modules.CARD, parentLayoutId: this.id } },
                    { onCardCreate: () => console.log()}
                )
                this.onCardCreate(newCardModule)
            }}
        />
    }


    showCardContainer = () => {
        this.toggleModal(<BoxedModule
            moduleId={''}
            moduleName={''}
            onDelete={() => {
                this.onDelete && this.onDelete(this)
            }}
            dragHandle={''}
            onHeaderNameUpdate={(name: string) => {

            }}
            content={<div>
                {this.createCardDialog()}
            </div>}
        />
        )
    }

    render(): JSX.Element {
        return <div
            className={classNames(
                `card`,
                this.parentModule?.id === "LayoutStartPoint" && 'card-no-parent'
            )}
        >
            <div
                data-testid="card-click-area"
                className="prevent-drag"
                onClick={() => {
                    this.showCardContainer()
                }}
            >
                {this.name}
            </div>
        </div>
    }

    toFirebaseObject() {
        return { id: this.id, name: this.name, type: this.type }
    }

}