import { getDuration, getNow, getTimeLeft, timestampHasPassed, convertTimestampToStringDateAndTime, convertTimestampToStringDate } from './../../utils/TimeUtils';
import { Activity } from '../../data/activity/Activity';
import { clearObjectOfUndefines } from '../../utils/Utils';
import { deleteData, writeData } from '../../utils/Firebase';
import { ActivityStatus, data, getDataPath } from '../../utils/constants';

export interface IScheduledActivity {
    id: string;
    // date: string;
    latestStartTime?: string;
    latestCompleted?: number;
    content: string;
    status: ActivityStatus;
    timed?: boolean;
    startTime?: number;
    lastStartTime?: number;
    stopTime?: number;
    totalTime?: number;
    extra?: any;
    dueTime?: number;
    activityId?: string;
}

export class ScheduledActivity implements IScheduledActivity {
    id: string;
    // date: string;
    content: string;
    status: ActivityStatus;
    latestStartTime?: string;
    latestCompleted?: number;
    timed?: boolean;
    startTime?: number;
    lastStartTime?: number;
    stopTime?: number;
    totalTime?: number;
    extra?: any;
    dueTime?: number;
    activityId?: string;

    constructor(props: IScheduledActivity) {
        Object.assign(this, props);
        clearObjectOfUndefines(this);
    }

    timeRanOut() {
        if (this.dueTime === 0) {
            return false;
        }
        const ranOut = ((timestampHasPassed(this.dueTime) && (this.status !== ActivityStatus.DIDNT_DO && this.status !== ActivityStatus.IN_PROGRESS && this.status !== ActivityStatus.DONE)))
        return ranOut;
    }

    getActivity(activities: Activity[]) {
        return activities.find(a => a.id === this.getActivityId());
    }


    // printCurrentTimer(): string {

    // }

    expired() {
        this.status = ActivityStatus.DIDNT_DO;
        this.dueTime = -1;
    }

    play() {
        const time = getNow();
        if (!this.lastStartTime) {
            this.startTime = time;
        }
        this.lastStartTime = time;
        this.timed = true;
        this.status = ActivityStatus.IN_PROGRESS;
    }

    pause() {
        const currentTime = new Date().getTime();
        this.status = ActivityStatus.PAUSED;
        const timeSinceLastStart = currentTime - this.lastStartTime
        this.totalTime = (this?.totalTime || 0) + timeSinceLastStart;
    }

    finish(user: string) {
        const currentTime = getNow();
        this.stopTime = currentTime;
        this.status = ActivityStatus.DONE;
        const timeSinceLastStart = currentTime - this.lastStartTime;
        this.totalTime = this.timed ? ((this.totalTime || 0) + timeSinceLastStart) : 0;
        if (isNaN(this.totalTime)) {
            this.totalTime = 0;
        }

        // const activity: Activity = dataContext.activities.getActivity(this.activityId);
        // if (activity) {
        //     const interval = activity.getInterval();
        //         if(interval > 0){
        //             const dueTime = fromTime + interval;
        //             dataContext.scheduledActivities.createScheduledActivity(this.content, dataContext, true, activity.id, undefined, dueTime)
        //         }
        // }

        // dataContext.scheduledActivities.updateScheduledActivity(this, dataContext, this.startTime);
        this.updateFirebase(user);
    }

    printTimeLeft(): string {
        if (this.dueTime === 0) {
            return "";
        }
        // return this.status !== ActivityStatus.DIDNT_DO ? `${getTimeLeft(this?.dueTime)}` || undefined : undefined;
        const timePassed = this.dueTime < getNow();
        return `${timePassed ? "-" : ""}${getTimeLeft(this.dueTime)}`
    }

    pastDueTime(): boolean {
        return this?.dueTime ? this.dueTime < getNow() : false;
    }

    printDueTime(): string {
        if (!this.dueTime) {
            return "";
        }
        const timeString = convertTimestampToStringDateAndTime(this.dueTime);
        return timeString.slice(2, timeString.length);
    }

    printDueTimeTimeLeft(): string {
        if (!this.dueTime) {
            return "";
        }
        const timeLeft = getDuration(this.dueTime - getNow())
        return timeLeft;
    }

    isTimed() {
        return this.timed === true || this.totalTime ? true : false
    }

    getTotalRunTime(): number {
        if (this.status === ActivityStatus.PAUSED) {
            return this.totalTime;
        }
        const currentTime = getNow();
        const totalTime = (this.totalTime || 0) + (currentTime - this.lastStartTime || 0);
        return totalTime;
    }

    isPaused(): boolean {
        return this.status === ActivityStatus.PAUSED;
    }

    isInProgress(): boolean {
        return this.status === ActivityStatus.IN_PROGRESS;
    }

    notStarted(): boolean {
        return this.status === ActivityStatus.NOT_STARTED;
    }

    getTime(): string {
        const currentTotalTime = this.getTotalRunTime();
        return getDuration(currentTotalTime)
    }

    getTotalRunningTimeFormatted(): string {
        if (!this.totalTime) {
            return "";
        }
        return getDuration(this.totalTime)
    }

    counterIsActive() {
        return this &&
            this.timed === true &&
            this.status !== ActivityStatus.DIDNT_DO && this.status === ActivityStatus.IN_PROGRESS
    }

    getActivityId(): string {
        return this?.extra?.card?.extra?.activityId;
    }

    updateFirebase(user: string) {
        const path = getDataPath(user, data.SCHEDULED_ACTIVITY, this.id);
        if (isNaN(this.totalTime)) {
            this.totalTime = 0;
        }
        clearObjectOfUndefines(this)
        writeData(path, this);
    }

    isFinished() {
        return this.status === ActivityStatus.DONE;
    }

    getStartTimeDate(): string {
        return convertTimestampToStringDate(this.startTime) || null;
    }

    delete(user: string) {
        // calendarData.deleteScheduledActivity(user, this.id);
        deleteData(getDataPath(user, data.SCHEDULED_ACTIVITY, this.id))
        // delete dataContext.scheduledActivities.scheduledActivities[this.id];
        // dataContext.scheduledActivities.refreshScheduledActivities();
        // dataContext.calendarData.deleteScheduledActivity(this.getStartTimeDate(), this.id);
        // dataContext.calendarData.updateCalendarData();
    }


    // async changePlannedDate(newPlannedDate: string,){
    //     const oldPlannedDate = this.startTime;
    //     dataContext.calendarData.updateScheduledActivity(this, convertTimestampToStringDate(oldPlannedDate), newPlannedDate);
    // }

    toString() {
        return `${this.content} - ${this.printDueTime()}`
    }

    addToSchedule(timestamp: number) {

    }

}