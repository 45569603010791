import * as React from 'react';
import { BaseModule, IBaseModule } from "../BaseModule";
import { Activity } from '../../../data/activity/Activity';
import { getDuration, getNow } from '../../../utils/TimeUtils';
import { Button } from '../../../components/button/Button';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { ScheduledActivityViewer } from '../../../components/activity_generator/ScheduledActivityViewer';
import { IRow, ICell, Table } from '../../../components/table/Table';
import { Row } from '../../../components/layout/row/Row';
import { PlusIcon } from '../../../icons/PlusIcon';
import { QuickActivityCreator } from '../../../components/quick_activity_creator/QuickActivityCreator';
import { ActivityStatus, breakpointTypes, getModulesPath, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts } from '../../../utils/constants';
import { TData } from '../../../app/interface';
import { writeData } from '../../../utils/Firebase';
import { Column } from '../../../components/layout/column/Column';
import { MarginBottom } from '../../../components/layout/margin/MarginBottom';
import { DateTimepicker } from '../../../components/pickers/datetime_picker/DateTimepicker';
import { generateId } from '../../../utils/Utils';

type TRowsData = { activity: Activity, scheduledActivities: ScheduledActivity[] }[];

export interface IActivityTableModuleProps {
    onCreateActivity: (activity: Activity) => Promise<void>;
    onDeleteActivity: (activity: Activity) => void;
    onCreateScheduledActivity: (activity: ScheduledActivity) => void;
    onFinishScheduledActivity: (scheduledActivity: ScheduledActivity) => void;
    onDeleteScheduledActivity: (scheduledActivity: ScheduledActivity) => void;
    initialData?: TRowsData;
}

export class ActivityTableModule extends BaseModule {
    onSubscribe: Function;
    onCreateActivity;
    onDeleteActivity;
    onFinishScheduledActivity;
    onDeleteScheduledActivity;
    onCreateScheduledActivity;
    rowData: TRowsData;
    dataContextArgs?: ["activities.activities"];

    constructor(
        baseProps: IBaseModule,
        activityTableModuleProps: IActivityTableModuleProps
    ) {
        super(baseProps);
        // console.log(activityTableModuleProps.onCreateScheduledActivity);
        Object.assign(this, activityTableModuleProps);
        this.rowData = activityTableModuleProps.initialData || [];
        this.autoPackingDimensions = { w: 24, h: 30 };
        this.dataTestid = 'activity-table-module';
    }

    onDataContextUpdate = (dataContext: TData) => {
        if (dataContext?.activities?.activities?.length) {
            this.rowData = dataContext.activities.activities.map(activity => ({ activity, scheduledActivities: [] }));
            this.forceRender();
        }
    }

    addActivity(activity: Activity) {
        this.rowData.push({ activity, scheduledActivities: [] })
    }

    actionMenu?(appContext: any, dataContext: TData): JSX.Element[] {
        return null;
    }

    updateModuleFirebase() {
        const firebaseData: Omit<IBaseModule, "onDelete"> = { id: this.id, user: this.user, type: this.type, name: this.name }
        writeData(getModulesPath(this.user, this.id), firebaseData);
    }

    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }

    renderTopMenu(): JSX.Element {
        return <Row>
            <div
                className="center-content"
                data-testid="activity-table-create-activity-button"
                role="button"
                onClick={() => {
                    this.toggleModal(
                        <QuickActivityCreator
                            testId={'activity-creator'}
                            onCreate={async (activity: Activity) => {
                                this.addActivity(activity);
                                await this.onCreateActivity(activity);
                                this.closeModal()
                            }} />
                    )
                }}>
                Skapa ny aktivitet
                <PlusIcon />
            </div>
        </Row >
    }
    customDelete = (dataContext: TData): void => {
        dataContext.modules.deleteModule(this);
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: {
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.HALF,
                h: GridHeightsSM.FULL,
            },
            [breakpointTypes.LG]: {
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.HALF,
                h: GridHeightsLG.FULL,
            }
        }
    }

    setRows = (rowsData: TRowsData): IRow[] => {

        const getRowData = (
            scheduledActivities: ScheduledActivity[]
        ): {
            lastCompleted: ScheduledActivity,
            oldestOpen: ScheduledActivity,
            timeLeftToStart: number
        } => {
            scheduledActivities.sort((a, b) => a.startTime < b.startTime ? 1 : -1);
            const allCompleted: ScheduledActivity[] = scheduledActivities.filter(s => s.status === ActivityStatus.DONE);
            const lastCompleted = allCompleted[0];
            const allOpen = scheduledActivities.filter(s => s.status !== ActivityStatus.DONE);
            const oldestOpen: ScheduledActivity = allOpen[0];
            const timeLeftToStart = oldestOpen?.startTime ? oldestOpen.startTime - getNow() : 0

            return { lastCompleted, oldestOpen, timeLeftToStart };
        }

        const getRows = (rowsData: TRowsData): IRow[] => {

            const rows: IRow[] = rowsData.map(rowData => {
                const { lastCompleted, timeLeftToStart, oldestOpen } = getRowData(rowData.scheduledActivities);
                let plannedStartTime;
                const row = {
                    id: rowData.activity.id,
                    cells: [
                        {
                            columnId: "name", content: <div data-testid="cell-content" onClick={() => {
                                // setShowModal(rowData.activity);
                                this.toggleModal(
                                    <Column alignLeft>
                                        <Button dataTestid="manual-scheduling-button" label={'Planerad starttid för aktiviteten'} onClick={() => {
                                            this.toggleModal(
                                                <>
                                                    {
                                                        rowData.activity && <DateTimepicker
                                                            defaultTime={new Date()}
                                                            separateTimeAndDate
                                                            onChange={time => plannedStartTime = time}
                                                        />
                                                    }
                                                    <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                                                        const newScheduledActivity: ScheduledActivity = new ScheduledActivity({ id: generateId(), content: rowData.activity.name, status: ActivityStatus.NOT_STARTED, activityId: rowData.activity.id, startTime: plannedStartTime.getTime() });
                                                        this.onCreateScheduledActivity(newScheduledActivity);
                                                        this.closeModal()
                                                    }} />
                                                </>
                                            )
                                        }} />
                                        <MarginBottom />
                                        <Button dataTestid="delete-activity" label={'Ta bort aktivitet'} onClick={async () => {
                                            this.rowData = this.rowData.filter(row => row.activity.id !== rowData.activity.id);
                                            this.onDeleteActivity(rowData.activity);
                                            // dataContext.activities.deleteActivity(activity, true);
                                            this.closeModal();
                                        }} />
                                        <MarginBottom />
                                    </Column>
                                    // <ActivityDialog
                                    //     activity={rowData.activity}
                                    //     onClose={() => { this.closeModal(); }}
                                    //     onCreateScheduledActivity={this.onCreateScheduledActivity}
                                    //     onDeleteActivity={(activity: Activity) => {
                                    //         this.rowData = this.rowData.filter(row => row.activity.id !== activity.id);
                                    //         this.onDeleteActivity(activity);
                                    //         this.closeModal();
                                    //     }} />
                                )
                            }}>{rowData.activity.name || "Error"}</div>
                        },
                        // { columnId: "interval", content: rowData.activity.printInterval() },
                        { columnId: "lastCompleted", content: lastCompleted?.stopTime ? getDuration(getNow() - lastCompleted.stopTime) : "" },
                        { columnId: "dueTime", content: timeLeftToStart, extra: { timeLeftToStart, oldestOpen } },
                        // { columnId: "timeLeftInterval", content: test },
                        {
                            columnId: "complete", content: oldestOpen ? <Button size="small" dataTestid="complete-last" onClick={() => this.onFinishScheduledActivity(oldestOpen)} label={'OK'} /> : ""
                        },
                        {
                            columnId: "removeScheduled", content: timeLeftToStart ? <Button label="OK" dataTestid="remove-last" size="small" onClick={() => this.onDeleteScheduledActivity(oldestOpen)} /> : null
                        },
                    ],
                    rowStyling: { color: 'black' }
                }
                return row;
            })
            return rows;

        }
        const rws: IRow[] = getRows(rowsData);
        return rws;
    }

    renderTable = (rows: IRow[]) => <Table
        testId='activity-table'
        className="activity-table"
        customSort={{ colId: "dueTime", asc: false }}
        // mobileCells={["schedule", "name", "dueTime"]}
        settingsId={''}
        columns={[
            // { id: "status", label: "Status" },
            { id: "name", label: "Namn" },
            { id: "interval", label: "Intervall" },
            { id: "lastCompleted", label: "Senast slutförd" },
            {
                id: "dueTime", label: "Tid kvar",
                formatter: (row: IRow, cell: ICell, extra?: any) => {
                    return <div style={{ color: extra?.timeLeftToStart < 0 ? "red" : "green" }}>{extra?.timeLeftToStart ? getDuration(extra.timeLeftToStart) : ""}</div>;
                },
                onClickCellModal: async (row, cell, extra) => {
                    if (extra) {
                        return <ScheduledActivityViewer
                            onStartTimeUpdate={(...props) => {
                                console.log(props)
                            }}
                            dataTestid="activity-table-module"
                            scheduledActivity={extra.oldes}
                            onDelete={this.onDeleteScheduledActivity}
                            onSendToTodoList={undefined}
                        />
                    }
                    return <div>hej</div>
                },
            },
            // { id: "timeLeftInterval", label: "Tid kvar till intervall" },
            { id: "complete", label: "Klar" },
            { id: "removeScheduled", label: "Ta bort senast schedulerade" },
        ]}
        rows={rows} />

    render(): JSX.Element {
        const rows = this.setRows(this.rowData) || [];
        return this.renderTable(rows)
    }

    toFirebaseObject() {

    }

}