import * as React from 'react';
import { BaseModuleRenderer } from "./BaseModuleRenderer";
import Modal from '../../components/modal/Modal';
import { CloseIcon } from '../../icons/CloseIcon';
import { breakpointTypes, getModulesPath, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts } from '../../utils/constants';
import { TData } from '../../app/interface';
import { deleteData, writeData } from '../../utils/Firebase';

export interface IBaseModule {
    id: string;
    user: string;
    type: string;
    name: string;
    noDataContextSync?: boolean
    onDelete: (self: BaseModule, extra?: any) => void;
}

/**
 * Om inte en komponent återrenderas, Se BaseModuleRenderers memo och compare funktion så att alla BaseModule variabler är med där ifall några nya lagts till
 */
export abstract class BaseModule implements IBaseModule {
    id: string;
    name: string;
    user: string;
    dragHandle: string;
    type: string;
    onDelete: (self: BaseModule, extra?: any) => void;

    modalContent: JSX.Element;
    noBox?: boolean;
    select?: Function;
    autoPackingDimensions: { w: number, h: number }
    forceReactGridLayoutRerender?: Function
    dragged: boolean
    forceRender: Function;
    dataTestid: string;
    dataContextArgs?: string[];
    noDataContextSync?: boolean;
    
    constructor(props: IBaseModule) {
        Object.assign(this, props);
    }

    abstract getCreationDialog(onCreate: (module: BaseModule) => void): JSX.Element
    abstract renderTopMenu(dataContext: TData): JSX.Element
    abstract render(dataContext: TData): JSX.Element;
    abstract toFirebaseObject(): any;

    getFirebasePath() {
        return `${getModulesPath(this.user, this.id)}`;
    }

    updateFirebase() {
        writeData(this.getFirebasePath(), this.toFirebaseObject());
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: {
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.CARD,
                h: GridHeightsSM.CARD,
            },
            [breakpointTypes.LG]: {
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.CARD,
                h: GridHeightsLG.CARD,
            }
        }
    }

    actionMenu?(appContext, dataContext: TData): JSX.Element[] {
        return [];
    }

    onDataContextUpdate(dataContext: TData): void {

    }

    footer(dataContext: TData): JSX.Element {
        return null;
    };

    // delete = async (dataContext: TData, args?: any,) => {
    //     await dataContext.modules.deleteModule(this);
    //     await this.customDelete(dataContext)

    //     this.closeModal();
    // }

    toggleModal = (modalContent: JSX.Element = null) => {
        if (!modalContent) {
            this.modalContent = null;
        } else {
            this.modalContent = modalContent;
            this.forceRender()
        }
    }

    refreshModal = () => {
        this.toggleModal(this.modalContent);
    }

    closeModal = () => {
        this.toggleModal(null);
        this.forceRender && this.forceRender()
    }

    renderModal() {
        return this.modalContent && <Modal
            dataTestid={"modal"}
            onBackdropClick={() => this.closeModal()}
            showModal={!!this.modalContent}
            onEscape={() => {
                console.log("escape")
                this.closeModal()
            }}
            setShowModal={showModal => {
                this.toggleModal(showModal ? this.modalContent : null)
            }}
        >
            <CloseIcon onClick={() => { this.closeModal() }} />
            {this.modalContent}
        </Modal>
    }

    deleteFromFirebase() {
        deleteData(this.getFirebasePath());
    }

    renderModule(customDragHandle?: string, customElement?: (BaseModule: BaseModule) => JSX.Element, noDataContextSync?: boolean): JSX.Element {
        const dragHandle = customDragHandle /* || (this.sitemapData.type === modules.MODULE_OVERVIEW ? `handle-${this.sitemapData.id}` : (!parentId ? "" : `handle-${parentId}`)) */;
        this.noDataContextSync = noDataContextSync;
        return <>
            <BaseModuleRenderer baseModule={this} dragHandle={dragHandle} customElement={customElement} />
        </>
    }

}

