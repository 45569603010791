import { clearObjectOfUndefines, objectHasProperties, shuffleArray } from './../utils/Utils';
import { breakpointTypes, GET_USER, GridHeightsLG, GridWidthsLG, IGrid, IGridLayout, ILayouts } from "../utils/constants";
import { deleteData, writeData } from "../utils/Firebase";
import { TData } from '../app/interface';

export interface ILayout {
    [breakpointTypes.SM]?: { [key: string]: IGrid },
    [breakpointTypes.LG]?: { [key: string]: IGrid },
}

export class Layout implements ILayout {
    //@ts-ignore
    [breakpointTypes.SM]: { [key: string]: IGrid };
    //@ts-ignore
    [breakpointTypes.LG]: { [key: string]: IGrid };


    constructor(layout: ILayout) {
        Object.assign(this, layout);
        if(!this.lg) {
            this.lg = {};
        }
        if(!this.sm) {
            this.sm = {};
        }
    }

    getILayout = (): ILayout => {
        const iLayout: ILayout = { [breakpointTypes.SM]: { ...this[breakpointTypes.SM] }, [breakpointTypes.LG]: { ...this[breakpointTypes.LG] } }
        return iLayout;
    }

    getGridIds = (): string[] => {
        return Object.keys(this[breakpointTypes.LG]);
    }

    gridIsEqual(incomingGrid: IGrid, breakpoint: breakpointTypes) {
        const grid: IGrid = this[breakpoint][incomingGrid.i];
        return grid.w === incomingGrid.w && grid.h === incomingGrid.h && grid.x === incomingGrid.x && grid.y === incomingGrid.y && grid.isDraggable === incomingGrid.isDraggable;
    }

    layoutIsEqual(incomingLayout: IGrid[], breakpoint: breakpointTypes) {
        return incomingLayout.every((incomingGrid: IGrid) => {
            const isEqual = this.gridIsEqual(incomingGrid, breakpoint)
            return isEqual;
        })
    }

    setAllDraggable() {
        Object.values(this[breakpointTypes.LG]).forEach((grid: IGrid) => {
            this[breakpointTypes.LG][grid.i].isDraggable = true;
        })
    }

    setAllNonDraggable() {
        Object.values(this[breakpointTypes.LG]).forEach((grid: IGrid) => {
            this[breakpointTypes.LG][grid.i].isDraggable = false;
        })
    }

    updateGridItems(gridItems: IGrid[], breakpoint: breakpointTypes) {
        gridItems.forEach((gridItem: IGrid) => {
            clearObjectOfUndefines(gridItem);
            this[breakpoint][gridItem.i] = gridItem;
        })
        // if (dataContext && updateLayout) {
        //     // dataContext.layout.setLayout(this);
        // }
        // if (updateFirebase) {
        //     this.updateFirebaseModule(user);
        // }
    }


    setLockedItems = (locked, currentBreakpoint: breakpointTypes) => {
        const currentLayout = this[currentBreakpoint];
        Object.values(currentLayout).forEach((grid: IGrid) => {
            grid.isDraggable = !locked;
        })
    }

    layoutArrayToLayoutObject(layout: IGrid[]): { [key: string]: IGrid } {
        const layoutObject = {}
        for (const l of layout) {
            layoutObject[l.i] = l;
        }
        return layoutObject;
    }

    objectToArray(layout: { [key: string]: IGrid }): IGrid[] {
        return Object.values(layout);
    }

    updateLayout(dataContext: TData, user: string, layout: IGrid[], breakpoint: breakpointTypes, updateFirebase: boolean) {
        const otherLayout: IGrid[] = this.objectToArray(this.getOtherLayout(breakpoint));
        console.log(otherLayout.length, layout.length);
        // if (layout.length !== otherLayout.length) {
        //     alert("items have been removed from array, wont update!");
        // }
        // else {
            this[breakpoint] = this.layoutArrayToLayoutObject(layout);
            // dataContext.layout.setLayout(this);
        // }
    }

    updateLayouts(dataContext: TData, user: string, layouts: IGridLayout) {
        this[breakpointTypes.SM] = layouts[breakpointTypes.SM].reduce((previous, current, i, arr) => {
            previous[current.i] = current;
            return previous;
        }, {});
        this[breakpointTypes.LG] = layouts[breakpointTypes.LG].reduce((previous, current, i, arr) => {
            previous[current.i] = current;
            return previous;
        }, {});
        // dataContext.layout.setLayout(this);
    }

    getGrid(gridid: string) {
        return {
            id: gridid,
            [breakpointTypes.SM]: this[breakpointTypes.SM][gridid],
            [breakpointTypes.LG]: this[breakpointTypes.LG][gridid]
        }
    }

    getGrids(gridIds: string[], breakpoint): IGrid[] {
        const grids: IGrid[] = gridIds.map((gridId: string) => {
            return this[breakpoint][gridId];
        })
        return grids?.filter(g => g) || [];
    }

    removeGrid(gridId: string): ILayouts {
        const gridLayout: ILayouts = { id: gridId, [breakpointTypes.SM]: { ...this[breakpointTypes.SM][gridId] }, [breakpointTypes.LG]: { ...this[breakpointTypes.LG][gridId] } }
        delete this[breakpointTypes.SM][gridId];
        delete this[breakpointTypes.LG][gridId];

        // dataContext.layout.setLayout(this);


        // if (!objectHasProperties(gridLayout.sm)) {
        //     console.log(gridLayout);
        // }
        return gridLayout;
    }

    getGridsClone(breakpoint): IGrid[] {
        const grids: IGrid[] = Object.values(this[breakpoint])
        return grids.map((grid: IGrid) => {
            const gridClone: IGrid = { ...grid } as IGrid
            return gridClone
        });
    }

    addGrid(dataContext: TData, grid: ILayouts, user, updateFirebase = true) {
        if (!objectHasProperties(grid)) {
            console.log(grid);
        }
        this[breakpointTypes.SM][grid.id] = { ...grid[breakpointTypes.SM] };
        this[breakpointTypes.LG][grid.id] = { ...grid[breakpointTypes.LG] };

        // dataContext.layout.setLayout(this);
    }

    getOtherLayout(breakpoint: breakpointTypes) {
        return breakpoint === breakpointTypes.SM ? this.getLayout(breakpointTypes.LG) : this.getLayout(breakpointTypes.SM)
    }

    getLayout(breakpoint: breakpointTypes) {
        return this[breakpoint]
    }

    getAllGrids(breakpoint: breakpointTypes): IGrid[] {
        const layout = this.getLayout(breakpoint);
        return Object.values(layout);
    }

    getKeys(breakpoint: breakpointTypes): string[] {
        if (this[breakpoint]) {
            return Object.keys(this[breakpoint])
        }
        return [];
    }

    // async verticalSort(user, sortedIds: string[], breakpoint: breakpointTypes, dataContext, updateLayout, updateFirebase) {
    //     let lastAccumulatedY = 0;
    //     sortedIds.forEach((id: string) => {
    //         this[breakpoint][id].y = lastAccumulatedY;
    //         lastAccumulatedY += this[breakpoint][id].h;
    //     })
    //     if (updateLayout) {
    //         this.updateGridItems(Object.values(this[breakpoint]), breakpoint, dataContext, updateLayout, updateFirebase);
    //     }
    // }

    autoLayout(dataContext: TData, user: string, shuffle: boolean = false) {
        let x = 0;
        let y = 0;
        const lgArr = Object.values(this[breakpointTypes.LG]);
        const arr = shuffle ? shuffleArray(lgArr) : lgArr;
        arr.forEach((grid, i) => {
            let newX, newY;

            newX = x === 0 ? 0 : x * GridWidthsLG.CARD;
            newY = y < 1 ? 1 : y * GridHeightsLG.CARD;
            // if (i > 3) {
            // }
            // else {
            //     newY = 1;
            // }

            console.log(newX, newY);
            grid.x = newX;
            grid.y = newY;
            ++x;
            if (x > 3) {
                x = 0;
                ++y;
            }
        })
        this.updateLayouts(dataContext, user, this.getArrays());
    }

    printGridItems(currentBreakpoint: breakpointTypes) {
        console.log(Object.values(this[currentBreakpoint]).map(l => ({ id: l.i, y: l.y })).sort((a, b) => a.y - b.y)[0].id)
        // const lay = Object.values(this[currentBreakpoint]).map(l => ({ id: l.i, y: l.y })).sort((a, b) => a.y - b.y)
        // lay.forEach((l, i) => {
        //     console.log(l.id, l.y)
        // })
    }

    get(): ILayout {
        return { [breakpointTypes.SM]: this[breakpointTypes.SM], [breakpointTypes.LG]: this[breakpointTypes.LG] }
    }

    getArrays(): IGridLayout {
        return { [breakpointTypes.SM]: Object.values(this[breakpointTypes.SM]), [breakpointTypes.LG]: Object.values(this[breakpointTypes.LG]) }
    }



}