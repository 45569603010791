import * as React from 'react'
import { ScheduledActivity } from './ScheduledActivity'
import { Column } from '../layout/column/Column'
import { Row } from '../layout/row/Row'
import Modal from '../modal/Modal'
import { DateTimepicker } from '../pickers/datetime_picker/DateTimepicker'
import { AppContext } from '../../app/AppContext'
import { Button } from '../button/Button'
import { PausIcon } from '../../icons/PausIcon'
import { StopIcon } from '../../icons/StopIcon'
import { ActivityStatus, modules } from '../../utils/constants'
import { readData } from '../../utils/Firebase'
import { TodoBoardModule } from '../../module/modules/TodoBoardModule/TodoBoardModule'
import "./ScheduledActivityViewer.scss"
import { MarginRight } from '../layout/margin/MarginRight'
import { addHours } from 'date-fns'

const ScheduledActivityViewer = ({ scheduledActivity, onDelete, onSendToTodoList, onStartTimeUpdate, onDueDateUpdate, onFinish, showSendToTodo, dataTestid = undefined, date = undefined }: { scheduledActivity: ScheduledActivity, onDelete: Function, onSendToTodoList: Function, onStartTimeUpdate: Function, onDueDateUpdate?: Function, onFinish?: Function, showSendToTodo?: boolean, dataTestid?: string, date?: string }) => {
    const { user } = React.useContext(AppContext);
    const [showModal, setShowModal] = React.useState("");
    const [dueTime, setDueTime] = React.useState<number>(0);
    const [startTime, setStartTime] = React.useState<number>(new Date().getTime());
    const [hasDate, setHasDate] = React.useState<boolean>(false);

    React.useEffect(() => {
        setHasDate(scheduledActivity?.dueTime > 0)
    }, [])

    const renderTimer = () => {
        // let activity: Activity;
        // if (scheduledActivity.activityId) {
        //     activity = activities.getActivity(scheduledActivity.activityId);
        // }

        return <Column>
            {scheduledActivity.getTotalRunningTimeFormatted()}
        </Column>
    }


    const handleConfirm = async () => {
        // const oldDate = scheduledActivity.dueTime
        // dataContext.scheduledActivities.updateScheduledActivity(scheduledActivity, dataContext, startTime, dueTime);
        setHasDate(true);

        if (onDueDateUpdate) {
            onDueDateUpdate();
        }
        setShowModal("")
    }

    const defaultTime = scheduledActivity?.startTime && !Number.isNaN(scheduledActivity?.startTime) ? new Date(scheduledActivity?.startTime) : addHours(new Date(), 1);

    const renderDatePicker = () => {
        return <>
            {
                scheduledActivity && <DateTimepicker
                    defaultTime={defaultTime}
                    separateTimeAndDate
                    onChange={(d) => {
                        setStartTime(d.getTime())
                    }}
                />
            }
            <Button
                label={'Bekräfta'}
                dataTestid='datetime-picker-confirm-button'
                onClick={async () => {
                    // await dataContext.scheduledActivities.updateScheduledActivity(scheduledActivity, dataContext, startTime);
                    // dataContext.calendarData.addScheduleToDate(scheduledActivity, convertTimestampToStringDate(startTime))
                    onStartTimeUpdate();
                    setShowModal("")
                }} />
        </>
    }

    return (<div className="scheduled-activity-viewer">
        <Column alignLeft>
        <h1>{scheduledActivity.content}</h1>
            {/* Gymaktivitet: {isGymActivity ? 'true' : 'false'} */}
            {/* <Row><Label text={"Namn: "} /><Label text={scheduledActivity.content} /></Row> */}
            <Row style={{ marginBottom: '16px' }}>
                <Button
                    dataTestid='show-duetime-dialog'
                    label={hasDate ? 'Sätt ny tidpunkt för när aktiviteten senast ska vara slutförd' : 'Sätt tidpunkt för när aktiviteten senast ska vara slutförd'} onClick={() => {
                        setShowModal("dueTime");
                    }} />
                <MarginRight />
                <Button
                    dataTestid="manual-scheduling-button"
                    label={'Planerad starttid för aktiviteten'}
                    onClick={() => {
                        setShowModal("datepicker")
                    }} />
                <MarginRight />
                <Button
                    dataTestid="delete-scheduled-activity"
                    label={'Radera aktivitet'}
                    onClick={() => {
                        // if (scheduledActivity.startTime) {
                        //     const date = convertTimestampToStringDate(scheduledActivity.startTime)
                        //     dataContext.calendarData.removeScheduledActivity(scheduledActivity, date);
                        // }
                        // dataContext.scheduledActivities.deleteScheduledActivity(scheduledActivity, user, dataContext);
                        onDelete(scheduledActivity);
                    }} />
            </Row>
            {/* <Label text={`Time left: ${scheduledActivity?.printTimeLeft()}`} /> */}
            {/* {
                (timed && (scheduledActivity?.status === ActivityStatus.PAUSED || scheduledActivity?.status === ActivityStatus.NOT_STARTED)) &&
                <PlayIcon onClick={() => {
                    scheduledActivity.play();
                    scheduledActivity.updateFirebase(user);
                    calendarData.updateScheduledActivity(scheduledActivity, scheduledActivity?.date);
                    // activityModule.refresh();
                }} />
            } */}
            {scheduledActivity.printDueTime()}
            {
                scheduledActivity?.status === ActivityStatus.IN_PROGRESS && <Row><PausIcon onClick={() => {
                    scheduledActivity.pause();
                    scheduledActivity.updateFirebase(user);
                    // activityModule.refresh();
                }} />
                    <StopIcon onClick={() => {
                        // const activity: Activity = activities.getActivity(scheduledActivity.activityId);

                        // const totalTime = scheduledActivity.finish(activity, calendarData);
                        // activity.finish(user, totalTime, calendarData);

                        // scheduledActivity.updateFirebase(user);
                        // activity.updateFirebase(user);
                        // scheduledActivity.finish(user, dataContext);
                        if (onFinish) {
                            onFinish();
                        }
                        // activityModule.refresh();
                    }} /></Row>
            }
            {/* <Checkbox label="Tajmad" checked={scheduledActivity?.timed} onChange={checked => {
                setTimed(checked);
            }} /> */}
            {scheduledActivity?.timed && renderTimer()}
            {/* {isGymActivity && renderGymActivity()} */}
            {showSendToTodo && <Button label={'Skicka till köade uppgifter'}
                onClick={() => {
                    // sendToTodoList(dataContext, () => {
                    //     onSendToTodoList()
                    // })
                }
                }
            />
            }
        </Column>
        <Modal showModal={!!showModal} setShowModal={setShowModal} dataTestid={showModal === "datepicker" ? "datepicker" : ""}>
            {showModal === "dueTime" && <Column alignLeft>
                <div className="margin-bottom datetime-picker">
                    <DateTimepicker
                        dataTestid={dataTestid ? dataTestid + "-datetime-picker" : ""}
                        defaultTime={defaultTime}
                        separateTimeAndDate
                        onChange={date => {
                            setDueTime(date.getTime());
                        }} />
                </div>
                <Button label={'Avbryt'} onClick={() => setShowModal("")} />
                <Button label={'OK'} onClick={handleConfirm} />
            </Column>}
            {showModal === 'datepicker' && renderDatePicker()}

            {/* {showModal === "createGymProgram" && isGymActivity && <GymProgramCreator scheduledActivity={scheduledActivity} />} */}
            {/* {showModal === "startGymProgram" && isGymActivity && <GymProgram activity={activity} />} */}
        </Modal>
    </div>
    )
}

export { ScheduledActivityViewer }