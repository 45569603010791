import * as React from 'react'
import { BoxedModule } from '../BoxedModule'
import { DataContext } from '../../app/DataContext';
import { AppContext } from '../../app/AppContext';

const moduleStyling = {
    display: 'flex',
    // backgroundColor: 'lightgreen',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
}

const BaseModuleRenderer = ({ baseModule, dragHandle, customElement }) => {
    const dataContext = React.useContext(DataContext);
    const appContext = React.useContext(AppContext);
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const topMenu = baseModule.renderTopMenu(dataContext);
    const actionMenu = baseModule.actionMenu(appContext, dataContext);
    const content = baseModule.render(dataContext);
    const footer = baseModule.footer(dataContext)

    const getDataContextRefByString = (dataContext, refStrings: string[]) => {
        if (!refStrings?.length) return [];
        const strings = refStrings.map(refString => {
            let ref = dataContext;
            refString.split('.').forEach(prop => {
                ref = ref[prop];
            });
            return ref;
        })
        return strings;
    }

    React.useEffect(() => {
        baseModule.forceRender = forceUpdate;
        if (!baseModule.noDataContextSync) {
            baseModule.onDataContextUpdate(dataContext);
        }
    }, [...dataContext && baseModule?.dataContextArgs?.length > 0 ? getDataContextRefByString(dataContext, baseModule.dataContextArgs) : []])

    return (<div
        style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}
        className="base-module-renderer"
        data-testid={baseModule.dataTestid}
    >
        {baseModule.noBox && <div style={{height: '100%', justifyContent: 'center', alignItems: 'center'}} className={`no-box ${dragHandle}`}>
            {topMenu}
            {content}
        </div>}
        {customElement && <div
            className={`custom-element ${dragHandle ? " " + dragHandle : ""}`}
            style={moduleStyling}>
            {customElement(baseModule)}
        </div>
        }
        {!baseModule.noBox && !customElement &&
            <BoxedModule
                moduleName={baseModule.name}
                moduleId={baseModule.id}
                // sitemapModule={baseModule.sitemapData}
                actionMenu={actionMenu}
                dragHandle={dragHandle}
                // content={!sitemapData?.settings?.isCard ? content : <div>{sitemapData.name}</div>}
                topMenu={topMenu}
                content={content}
                footer={footer}
                onMinimize={() => { }/* (minimized: boolean) => onMinimizeModule(minimized, baseModule.sitemapData) */}
                onDelete={() => baseModule.onDelete && baseModule.onDelete(baseModule)}
                // lastChecked={lastChecked}
                onHeaderNameUpdate={newName => {
                    baseModule.sitemapData.name = newName;
                    baseModule.updateFirebase();
                    baseModule.refresh(dataContext);
                }}
            />
        }
        {baseModule.renderModal()}
    </div>
    )
}

export { BaseModuleRenderer };