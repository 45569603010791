import * as React from 'react'
import { CardModule, ICardModuleProps } from '../CardModule/CardModule';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { BoxedModule } from '../../BoxedModule';
import { Column } from '../../../components/layout/column/Column';
import { Button } from '../../../components/button/Button';
import { DateTimepicker } from '../../../components/pickers/datetime_picker/DateTimepicker';
import { MarginBottom } from '../../../components/layout/margin/MarginBottom';
import { deleteData } from '../../../utils/Firebase';
import { data, getDataPath, getModulesPath } from '../../../utils/constants';

export interface IScheduledActivityCardModuleProps {
    scheduledActivity: ScheduledActivity;
    onUpdateScheduledActivity: (updatedScheduledActivity: ScheduledActivityCardModule) => void;
}

export class ScheduledActivityCardModule extends CardModule implements IScheduledActivityCardModuleProps {
    scheduledActivity: ScheduledActivity;
    onUpdateScheduledActivity: (updatedScheduledActivity: ScheduledActivityCardModule) => void;

    constructor(
        baseProps,
        boardModuleProps: ICardModuleProps,
        scheduledActivityCardProps: IScheduledActivityCardModuleProps
    ) {
        super({ ...baseProps }, boardModuleProps);
        Object.assign(this, scheduledActivityCardProps)
        // this.scheduledActivity = scheduledActivity;
        // this.onUpdateScheduledActivity = onUpdateScheduledActivity;
        // this.onDelete = onDelete;
    }

    // försök flytta den här till TodoBoard så att motsvarande kort i dess board tas bort när den här köas upp till UpcomingBoardModule
    showCardContainer = () => {
        this.toggleModal(<BoxedModule
            moduleName={this.name}
            moduleId={this.id}
            dragHandle={this.dragHandle}
            // actionMenu={[<div>hej</div>]}
            content={
                <Column alignLeft>
                    <Button label={'Planerad starttid för aktiviteten'} onClick={() => {
                        this.toggleModal(<>
                            {
                                <DateTimepicker
                                    defaultTime={new Date()}
                                    separateTimeAndDate
                                    onChange={newTime => this.scheduledActivity.startTime = newTime.getTime()}
                                />
                            }
                            <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                                this.onUpdateScheduledActivity(this);
                                this.parentModule && this.parentModule.removeModule(this.id);
                                this.closeModal();
                            }} />
                        </>)
                    }} />
                    <MarginBottom />
                    <Button label={'Radera aktivitet'} onClick={() => {
                        if (this.parentModule) {
                            this.parentModule.removeModule(this.id)
                        }
                        
                    }} />
                </Column>
            }
            onMinimize={() => { }/* (minimized: boolean) => onMinimizeModule(minimized, baseModule.sitemapData) */}
            onDelete={() => {
                console.log(this.parentModule);
                if (this.parentModule) {
                    this.parentModule.removeModule(this.id);
                }
            }}
            onHeaderNameUpdate={newName => {

            }}
        />)
    }

    toFirebaseObject() {
        return { id: this.id, name: this.name, type: this.type, scheduledActivity: this.scheduledActivity.id }
    }

    deleteFromFirebase() {
        deleteData(getDataPath(this.user, data.SCHEDULED_ACTIVITY, this.id))
        deleteData(getModulesPath(this.user, this.id))
    }

}